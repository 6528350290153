@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#palette-collection{
  /* overflow-y: scroll; */
  font-family: inherit;
  /* font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
}

#palette-collection h2, #palette-collection h3 {
  margin: 0.1rem 0.5rem;
}

#palette-collection h4 {
  margin: 0.5rem 0.5rem;
}

#palette-collection .App {
  margin: auto;
  overflow: auto;
}

#palette-collection .link-button {
  width: min-content;
  display: flex;
  align-items: center;
}

#palette-collection a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

#palette-collection .card{
  box-shadow:  0 0px 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0.5rem;
  background-color: white;
}

#palette-collection .palette-collection-header {
  margin: 1rem 0rem;
  width: 100%;
}


#palette-collection .palette-collection-header input {
  padding: 0.5rem;
  width: 100%;
  border: none;
  font-size: inherit;
  font-family: inherit;
  box-sizing:border-box
}

.palette-collection-header input:hover {
  background-color: #EEEEEE;
  border: none;
}

#palette-collection .palette-collection-header input:focus {
  background-color: #EEEEEE;
  border: none;
}


#palette-collection button {
  display: block;
  color: white;
  padding: 1rem;
  margin: 0.5rem;
  border:0px;
  border-radius: 5px;
  text-transform: capitalize;
  cursor: pointer;
}

#palette-collection button:hover {
  color: #DDDDDD;
}

#palette-collection button:focus {
  outline: none;
}

#palette-collection button:active {
  transform: scale(0.995);
}

#palette-collection .wide-btn {
  display: flex;
  justify-content: space-between;
}

#palette-collection .add-box {
  margin: 0.25rem 0rem;
}

#palette-collection .draggable {
  padding: 0.25rem 0rem;
}

#palette-collection .box-wrapper {
  margin: 0rem 0.5rem;
}

#palette-collection .color-box-wrapper {
  margin: 0.5rem 0.5rem;
}


#palette-collection .color-box {
  min-height: 3.5rem;
  position: relative;
}

#palette-collection .color-box-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
  color: #999;
}

#palette-collection .color-header{
  display: flex;
  justify-content: space-between;
}

#palette-collection .color-box-icons{
  width: 50%;
  padding: 0rem 0.75rem;
  display:flex;
  position: relative;
}

#palette-collection .left-icons{
  justify-content: flex-start;
}

#palette-collection .right-icons{
  justify-content: flex-end;
}

#palette-collection .button-icon-btn {
  height: 1.25rem;
  width: 1.25rem;
}

#palette-collection .icon {
  color: #999;
  width: fit-content;
  margin: 0.25rem 0.5rem;
}

#palette-collection .icon:hover {
  color: #CCC;
}

#palette-collection .small-icon-btn {
  height: 1rem;
  width: 1rem;
}

#palette-collection .clickable-icon {
  cursor: pointer;
}

#palette-collection .delete-icon:hover {
  color: red;
}

#palette-collection .no-colors{
  color:grey;
  padding: 1rem;
}